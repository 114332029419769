import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/category/list.php',
    method : 'post',
    data : data
  })
}


//删除
export function deleteCategory(data) {
  return request({
    url : '/category/delete.php',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteCategory(data) {
  return request({
    url : '/category/deleteBatch.php',
    method : 'post',
    data : data
  })
}

//新增
export function createCategory(data) {
  return request({
    url : '/category/saveCategory.php',
    method : 'post',
    data : data
  })
}
//修改
export function updateCategory(data) {
  return request({
    url :'/category/updateCategory.php',
    method : 'post',
    data : data
  })
}

//全部
export function fetchAllCate() {
  return request({
    url : '/category/all.php',
    method : 'get',
  })
}
